import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Employee from '../components/Employee';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Container from '../components/Container';

const AboutPage = () => {
  const images = useStaticQuery(graphql`
    query {
      dk: file(relativePath: { eq: "dk.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eo: file(relativePath: { eq: "eo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ta: file(relativePath: { eq: "ta.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sr: file(relativePath: { eq: "sr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dw: file(relativePath: { eq: "dw.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ss: file(relativePath: { eq: "ss.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hw: file(relativePath: { eq: "hw.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ava: file(relativePath: { eq: "ava.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pf: file(relativePath: { eq: "pf.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const employees = [
    {
      name: 'Dany Kessel',
      description: 'Doktor i nationalekonomi och VD för Mitt Skolval.',
      image: images.dk.childImageSharp.fluid,
    },
    {
      name: 'Elisabet Olme',
      description:
        'Doktor i nationalekonomi och produktägare för Mitt Skolval.',
      image: images.eo.childImageSharp.fluid,
    },
    {
      name: 'Tommy Andersson',
      description:
        'Professor i nationalekonomi och senior rådgivare till Mitt Skolval.',
      image: images.ta.childImageSharp.fluid,
    },
    {
      name: 'Sona Rashid',
      description: 'Nationalekonom och ansvarig för kundteamet.',
      image: images.sr.childImageSharp.fluid,
    },
    {
      name: 'Amanda von Almassy',
      description: 'Kundansvarig',
      image: images.ava.childImageSharp.fluid,
    },
    {
      name: 'Daniel Winther',
      description: 'CTO (Tekniskt ansvarig)',
      image: images.dw.childImageSharp.fluid,
    },
    {
      name: 'Saga Swahn',
      description: 'Utvecklare',
      image: images.ss.childImageSharp.fluid,
    },
    {
      name: 'Henrik Westöö',
      description: 'Utvecklare',
      image: images.hw.childImageSharp.fluid,
    },
    {
      name: 'Philip Forsberg',
      description: 'Utvecklare',
      image: images.pf.childImageSharp.fluid,
    },
  ];

  return (
    <Layout>
      <SEO title="About" />

      <Container>
        <h1>Om oss</h1>
        <p>
          Mitt Skolval är ett idédrivet företag grundat av några av Sveriges
          främsta skolvalsforskare. Vår målsättning är att bygga en plattform
          där kommuner och fristående huvudmän enkelt kan hantera och
          administrera sina skolval och skolbyten.
        </p>
        <p>Vi har följande målsättningar:</p>
        <ol>
          <li>
            Det ska vara enkelt och gå snabbt att önska skola – vårdnadshavare
            ska inte behöva hantera ett antal olika system, eller behöva fundera
            över vilka skolor andra vårdnadshavare önskar, när de registrerar
            sina önskemål om skola.
          </li>
          <li>
            Skolvalet ska vara effektivt – så många elever som möjligt ska bli
            tilldelade en placering vid en önskad skola och kommuner och
            fristående skolor ska inte behöva lägga miljontals kronor på att
            administrera och hantera sina skolval.
          </li>
          <li>
            Skolvalet ska vara transparent och rättssäkert – förtursregler ska
            vara tydliga och som vårdnadshavare ska man enkelt kunna förstå
            varför ens barn, givet dessa regler, fick den skolplacering de fick.
          </li>
          <li>
            Skolvalet ska vara utvärderingsbart – man ska kunna lita på att den
            data som genereras i samband med skolvalet faktiskt reflekterar
            familjernas önskemål för skolor. Enbart då kan denna data användas
            för att utvärdera och analysera skolvalet.
          </li>
        </ol>
        <p>
          Utöver att bygga plattformen är vi inblandade i många andra
          skolvalsrelaterade aktiviteter. Exempelvis föreläser vi, skriver
          rapporter och deltar i seminarier. Vi arbetar också nationellt för att
          få till en vettig lagstiftning rörande skolval. Från och med 2019
          ordnar vi i maj varje år Skolvalskonferensen. Skolvalskonferensen är
          en samlingsplats för alla i Sverige som sysslar med skolval där vi kan
          träffas, utbyta erfarenheter och ta del av nya rön och aktuell
          forskning rörande skolval. Det är kostnadsfritt att delta i
          konferensen.
        </p>
        <div className="w-full mx-auto my-8">
          <h2 className="text-teal text-4xl mb-8">
            Vi som utgör Mitt Skolval är:
          </h2>
          <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 list-none">
            {employees.map((employee) => (
              <Employee
                key={employee.name}
                name={employee.name}
                description={employee.description}
                image={employee.image}
              />
            ))}
          </ul>
        </div>
        <p>
          Om ni är intresserade av vår plattform eller bara vill diskutera
          skolval är ni välkomna att höra av er till oss. Lättast når ni oss på
          hello@mittskolval.se.
        </p>
      </Container>
    </Layout>
  );
};

export default AboutPage;
