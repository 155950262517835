import React from 'react';
import Img from 'gatsby-image';
import { ImageSharpFluid } from '../types/graphql';

type EmployeeProps = {
  name: string;
  description: string;
  image: ImageSharpFluid;
};

const Employee = (props: EmployeeProps) => {
  const { name, description, image } = props;

  return (
    <li className="flex flex-row justify-start">
      <Img
        className="rounded-full min-w-40 md:min-w-48 h-40 md:h-48"
        fluid={image}
      />
      <div className="flex flex-col ml-8 justify-center">
        <h3 className="text-teal text-3xl mb-4">{name}</h3>
        <p>{description}</p>
      </div>
    </li>
  );
};

export default Employee;
